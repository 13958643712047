import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'

const PrivacyPage = ({ data }) => {
  const { title, content, seoMetaTags } = data.privacyInfoPage
  const updatedLinks = data.links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={seoMetaTags}>
      <Container color="bg-beige">
        <SectionTitle title={title} centered={true} main={true} />
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="prose prose-sm lg:prose max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-6"
        ></div>
      </Container>
    </Layout>
  )
}

export default PrivacyPage

export const termsQuery = graphql`
  query ($locale: String!) {
    privacyInfoPage: datoCmsInfoPage(
      code: { eq: "privacy" }
      locale: { eq: $locale }
    ) {
      id
      title
      content
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    links: allDatoCmsInfoPage(filter: { code: { eq: "privacy" } }) {
      nodes {
        slug
        locale
      }
    }
  }
`
